@import "bootstrap/scss/forms/form-check";

.form-control {
  @apply px-3 py-2 min-h-[48px] transition-all duration-200 text-sm text-black bg-white resize-none 
  rounded-[7px] border border-gray-100 cursor-pointer flex items-center shadow-md outline-none outline-0 outline-transparent w-full
  hover:text-inherit hover:bg-white hover:border-gray-100 hover:shadow-md hover:outline-none hover:outline-0 hover:outline-transparent
  focus:text-inherit focus:border-gray-100 focus:shadow-md focus:outline-none focus:outline-0 focus:outline-transparent
  active:text-inherit active:border-gray-100 active:shadow-md active:outline-none active:outline-0 active:outline-transparent
  disabled:bg-gray-100;
}
.form-control-group {
  @apply relative;
}
.form-control-group-icon {
  @apply absolute right-4 top-1/2 -translate-y-1/2;
}
.form-control .invalid {
  @apply border border-alert;
}
.form-control-number[type="number"] {
  -moz-appearance: textfield;
}
.form-control-number::-webkit-outer-spin-button,
.form-control-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.input-group {
  @apply relative;
}
.input-group-icon {
  @apply absolute left-4 top-1/2 -translate-y-1/2 pointer-events-none z-10;
}
.input-group-append,
.input-group-prepend {
  @apply flex items-center px-4 py-3 rounded-lg text-black border border-gray-200 bg-gray-100;
}
.input-group > .form-control:nth-child(2) {
  @apply rounded-l-lg pl-10;
}
.form-control-color {
  @apply text-sm appearance-none p-0 w-full h-12 border border-gray-100 bg-transparent transition-all duration-200 rounded-lg cursor-pointer;
}
.form-control-color::-webkit-color-swatch {
  @apply rounded-lg border-0;
}
.form-control-color::-moz-color-swatch {
  @apply rounded-lg border-0;
}
.form-rezisable {
  @apply resize-y;
}
.form-disabled {
  @apply text-sm bg-gray-100 opacity-100 cursor-not-allowed;
}
.form-required {
  @apply mr-2 after:content-['*'] after:text-alert;
}

input {
  @apply text-sm placeholder:text-gray-400;
}

.form-module {
  @apply flex flex-row;
}

.form {
  @apply text-sm;
  &-module {
    @apply flex flex-row;
  }
  &-header {
    @apply max-w-[240px] min-w-[240px] mr-8 font-semibold text-lg leading-[22px] text-black;
    .helper {
      @apply opacity-60 font-light text-sm leading-[17px] mt-4;
    }
  }
  &-holder {
    @apply flex-1 bg-gray-100 rounded-xl p-6;
  }
}

.form-check-input {
  @apply w-6 h-6 outline-0 shadow-none border-gray-300 rounded-md mt-0.5
  checked:bg-primary checked:border-primary
  indeterminate:bg-primary indeterminate:border-primary;
  &[type="checkbox"] {
    @apply rounded-md;
  }
}

.form-check-input[type="checkbox"]:indeterminate {
  @apply bg-primary border-primary;
}

@media screen and (max-width: 1024px) {
  .form {
    &-module {
      @apply flex flex-col;
    }
    &-header {
      @apply max-w-[1000px] mb-4;
    }
  }
}
