.orders-grid-holder {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 12px;
  column-gap: 12px;
  font-weight: 400;
  font-size: 13px;
  .order {
    justify-content: start;
    background-color: var(--color-gray-100);
    padding: 12px 8px;
    border-radius: 8px;
    .time {
      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: auto;
        width: 24px !important;
        height: 24px !important;
        border-radius: 50%;
        font-size: 16px;
        color: var(--color-black);
      }
    }
    .payment-transaction {
      border-radius: 8px;
      border: 1px solid var(--color-red);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px 6px;
      &.proceed {
        border: 1px solid var(--color-green);
      }
    }
    &.time-exceed {
      border: 2px solid var(--color-red);
    }
  }
}

.order-card {
  border-radius: 8px;
  overflow: hidden;
  &-header {
    padding: 16px;
    &-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 12px;
      background-color: var(--color-white-20);
      border-radius: 8px;
      &.red-outlined {
        border: 1px solid var(--color-red);
      }
    }
  }
  &-status {
    background-color: var(--color-white);
    padding: 8px 16px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 8px;
  }
  &-content {
    padding: 14px;
    border-bottom: 1px solid var(--color-gray-200);
    &-wrapper {
      display: flex;
      flex-direction: column;
      margin: 0 2px 2px 2px;
      border-radius: 0 0 7px 7px;
      flex: 1;
      background-color: var(--color-white);
    }
    &-comment {
      border-radius: 8px;
      border: 1px solid var(--color-gray-100);
      background: var(--color-white);
      padding: 16px;
    }
    &:last-child {
      border-bottom: none;
    }
    &-icon {
      transition: 0.25s all;
      &.active {
        transform: rotate(90deg);
      }
    }
  }
}

@media screen and (max-width: 2160px) {
  .orders-grid-holder {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1440px) {
  .orders-grid-holder {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 1024px) {
  .orders-grid-holder {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 768px) {
  .orders-grid-holder {
    grid-template-columns: repeat(1, 1fr);
  }
}
