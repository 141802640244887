.card {
  @apply relative flex flex-col justify-center items-start gap-12 self-stretch border border-gray-100 p-6 rounded-xl border-solid bg-white shadow-md;
}

.card-bio {
  @apply flex flex-col p-3 items-center gap-4 rounded-lg border border-gray-100 shadow-md;
}

.card-wrapper {
  @apply p-3 gap-4 flex-col items-start bg-white border border-gray-100 rounded-xl shadow-md;
}
