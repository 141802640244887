.static-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding: 16px 16px;
  color: var(--color-black);
  background-color: var(--color-white);
  &-btn-padding {
    padding: 12px 16px;
  }
  &-account {
    padding: 4px;
    border-radius: 8px;
    transition: 0.25s all;
    border: 1px solid transparent;
    color: var(--color-black);
    text-decoration: none;

    &:hover {
      background-color: var(--color-gray-70);
      border: 1px solid var(--color-primary);
      color: var(--color-black);
      text-decoration: none;
    }
  }
}

.header-content {
  display: flex;
  align-items: center;
  min-height: 80px;
  padding: 4px 16px;
  border-bottom: 1px solid var(--color-gray-100);
}

@media screen and (max-width: 768px) {
  .header-content {
    min-height: 52px;
  }
}
