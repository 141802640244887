.tab {
  width: 100%;
  border: none;
  overflow-x: scroll;
  background-color: var(--color-white);

  &-nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: end;
    gap: 32px;
    &-link {
      display: flex;
      padding: 20px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: var(--color-gray-400);
      font-weight: 400;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: 0.25s;
      &.active {
        color: var(--color-black);
        border-bottom: 2px solid var(--color-black);
      }
      &.disabled {
        background-color: var(--color-gray-70);
        cursor: not-allowed;
      }
    }
  }
}
