@import "theme";

$colors: (
  primary: #a165fd,
  black: #151324,
  gray-800: #2c343d,
  gray-700: #3d4e5f,
  gray-600: #15222f,
  gray-500: #464752,
  gray-400: #868688,
  gray-370: #8997a1,
  gray-350: #b8b8b8,
  gray-300: #c0c0c3,
  gray-200: #dadce0,
  gray-100: #edeef0,
  gray-70: #f3f4fa,
  gray-50: #f9f9fa,
  white: #ffffff,
  warn: #f8d134,
  red: #f83434,
  green: #0bd360,
  divider: #efefef
);

$opacity: (
  8: 0.08,
  10: 0.1,
  12: 0.12,
  20: 0.2
);

@each $color-name, $color-value in $colors {
  .color-#{"" + $color-name} {
    color: #{rgba($color-value, 1)};
  }
  // .bg-#{"" + $color-name} {
  //   background-color: #{rgba($color-value, 1)} !important;
  // }
  @each $opacity-name, $opacity-value in $opacity {
    .color-#{"" + $color-name}-#{$opacity-name} {
      color: #{rgba($color-value, $opacity-value)};
    }
    // .bg-#{"" + $color-name}-#{$opacity-name} {
    //   background-color: #{rgba($color-value, $opacity-value)} !important;
    // }
  }
}

:root {
  @each $color-name, $color-value in $colors {
    --color-#{"" + $color-name}: #{rgba($color-value, 1)};
  }
  @each $color-name, $color-value in $colors {
    @each $opacity-name, $opacity-value in $opacity {
      --color-#{"" + $color-name}-#{"" + $opacity-name}: #{rgba($color-value, $opacity-value)};
    }
  }
  --shadow-md: 0 0 8px 0 rgba(0, 0, 0, 0.04);
  --shadow-sm: 0 1px 4px 0 rgba(35, 57, 66, 0.21);
}
