.line {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 7px;
    width: 5px;
    height: calc(100% + 20px);
    background-color: var(--line-bg-color, #c4c4c4);
  }
}
.dot {
  position: absolute;
  top: 4px;
}
