.dropzone {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 8px;
  padding: 64px 32px;
  border-style: dashed;
  border-color: var(--color-gray-100);
  background-color: var(--color-white);
  box-shadow: var(--shadow-md);
  margin-bottom: 24px;

  &.active {
    background-color: var(--color-gray-100);
  }

  &-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: var(--color-white);
    box-shadow: var(--shadow-md);
    cursor: pointer;
    border-radius: 12px;
    border: 1px solid var(--color-gray-100);

    &-container {
      width: 240px;
      height: 240px;
      position: relative;

      .remove {
        position: absolute;
        top: -6px;
        right: -6px;
        min-width: 16px;
        min-height: 16px;
        background-color: var(--color-white);
        color: var(--color-black);
        font-size: 10px;
        transform: rotate(45deg);
        padding: 4px;
        border-radius: 50%;
        box-shadow: var(--shadow-sm);
        cursor: pointer;

        &:hover {
          background-color: var(--color-red);
          color: var(--color-white);
        }
      }
    }
  }
}
