.spotlight {
  z-index: inherit !important;
  outline: inherit !important;
}

.spotlight-wrapper {
  background-color: black;
  top: 0px;
  left: 0px;
  z-index: 100000;
}

.spotlight-wrapper-main {
  opacity: 0.5;
  left: 0px;
  top: 0px;
  right: 331px;
  position: fixed;
  z-index: 100000;
  pointer-events: none;
}

.spotlight-portal {
  position: absolute;
  z-index: 1000000 !important;
  background-color: var(--color-white);
  padding: 12px 16px;
  transition: transform 0.3s ease 0s;
  top: 0px;
  left: 0px;
  border-radius: 8px;
  margin: 4px 0px;
}

#root {
  :has(.spotlight-wrapper) {
    .spotlight {
      z-index: 1000000 !important;
      outline: 6px solid rgba(#a165fd, 0.4) !important;
    }
  }
}

.spotlight-svg {
  position: "fixed";
  z-index: 100000;
  top: 0;
  left: 0;

  &-pulse {
    fill: var(--color-primary);
    animation: pulse 2s ease infinite;
    transform-origin: center;
  }

  @keyframes pulse {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.2;
    }
  }
}

.onboarding {
  display: flex;
  flex-direction: column;
  &-wrapper {
    display: flex;
    flex-direction: column;
    padding: 16px 24px 8px 24px;
  }
  &-checkpoint {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-gray-100);
    background-color: var(--color-gray-50);
    &:has(img) {
      border: 1px solid var(--color-primary);
      background-color: var(--color-primary);
      color: var(--color-white);
    }
  }
  &-section {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    border-bottom: 1px solid var(--color-gray-100);
    &:last-child {
      border-bottom: none;
    }
  }
  &-action {
    width: calc(100% - 40px);
    margin-left: 40px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 32px;
    border: 1px solid var(--color-gray-100) !important;
    background-color: var(--color-white) !important;
    box-shadow: var(--shadow-md) !important;
    border-radius: 8px;
  }
  &-banner {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    box-shadow: var(--shadow-md);
    border: 1px solid var(--color-gray-100);
    padding: 16px;
    gap: 16px;
    border-radius: 8px;
  }
}
