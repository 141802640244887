.ck-view {
  border: 1px solid #edeef0;
  box-shadow: var(--shadow-md);
  max-width: 21cm;
  border-radius: 12px;
  padding: 24px 32px;
  margin: 0;
}

.ck-view-modal {
  width: calc(21cm + 48px);
  min-width: calc(21cm + 48px);
}

@media screen and (max-width: 768px) {
  .ck-view {
    padding: 0;
    border: none;
    border-radius: 0;
    box-shadow: none;
    zoom: 0.7;
  }
}

.ck.ck-editor {
  box-shadow: var(--shadow-md);
}

.ck.ck-editor__editable_inline {
  background-color: var(--color-gray-50);
  min-height: 240px;
}

.ck-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin: 16px auto;
}

.ck-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  background-color: #a165fd;
  box-shadow: var(--shadow-md);
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  width: max-content;
}

.ck-view .m-0 {
  margin: 0 !important;
}

.ck-view .m-0 {
  padding: 0 !important;
}

.ck-view .table > figcaption {
  display: table-caption;
  caption-side: top;
  word-break: break-word;
  text-align: center;
  color: var(--ck-color-table-caption-text);
  background-color: var(--ck-color-table-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}

.ck-view .table {
  margin: 4px auto;
  display: table;
}

.ck-view .table table {
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px double transparent;
}

.ck-view .table table td,
.ck-view .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid transparent;
}

.ck-view .table table th {
  font-weight: bold;
  background: #0000000d;
}

.ck-view[dir="rtl"] .table th {
  text-align: right;
}

.ck-view[dir="ltr"] .table th {
  text-align: left;
}

.ck-view .table .ck-table-resized {
  width: 100% !important;
  table-layout: fixed;
}

.ck-view .table table {
  width: 100% !important;
  table-layout: fixed;
  overflow: hidden;
}

.ck-view .table td,
.ck-view .table th {
  overflow-wrap: break-word;
  position: relative;
}

.ck-view .media {
  clear: both;
  margin: 0.9em 0;
  display: block;
  min-width: 15em;
}

.ck-view .todo-list {
  list-style: none;
}

.ck-view .todo-list li {
  margin-bottom: 5px;
}

.ck-view .todo-list li .todo-list {
  margin-top: 5px;
}

.ck-view .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}

.ck-view .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid #333333;
  border-radius: 2px;
  transition:
    250ms ease-in-out box-shadow,
    250ms ease-in-out background,
    250ms ease-in-out border;
}

.ck-view .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: "";
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8) calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}

.ck-view .todo-list .todo-list__label > input[checked]::before {
  background: #26ab33;
  border-color: #26ab33;
}

.ck-view .todo-list .todo-list__label > input[checked]::after {
  border-color: #ffffff;
}

.ck-view .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}

.ck-view .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 0.9em auto;
  min-width: 50px;
}

.ck-view .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 100%;
}

.ck-view .image-inline {
  display: inline-flex;
  max-width: 100%;
  align-items: flex-start;
}

.ck-view .image-inline picture {
  display: flex;
}

.ck-view .image-inline picture,
.ck-view .image-inline img {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}

.ck-view .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}

.ck-view .image.image_resized img {
  width: 100%;
}

.ck-view .image.image_resized > figcaption {
  display: block;
}

.ck-view .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: var(--ck-color-image-caption-text);
  background-color: var(--ck-color-image-caption-background);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}

.ck-view .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}

.ck-view .marker-green {
  background-color: var(--ck-highlight-marker-green);
}

.ck-view .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}

.ck-view .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}

.ck-view .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}

.ck-view .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}

.ck-view ol {
  list-style-type: decimal;
}

.ck-view ol ol {
  list-style-type: lower-latin;
}

.ck-view ol ol ol {
  list-style-type: lower-roman;
}

.ck-view ol ol ol ol {
  list-style-type: upper-latin;
}

.ck-view ol ol ol ol ol {
  list-style-type: upper-roman;
}

.ck-view ul {
  list-style-type: disc;
}

.ck-view ul ul {
  list-style-type: circle;
}

.ck-view ul ul ul {
  list-style-type: square;
}

.ck-view ul ul ul ul {
  list-style-type: square;
}

.ck-view .image-style-block-align-left,
.ck-view .image-style-block-align-right {
  max-width: calc(100% - var(--ck-image-style-spacing));
}

.ck-view .image-style-align-left,
.ck-view .image-style-align-right {
  clear: none;
}

.ck-view .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}

.ck-view .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}

.ck-view .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}

.ck-view .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}

.ck-view .image-style-block-align-right {
  margin-right: 0;
  margin-left: auto;
}

.ck-view .image-style-block-align-left {
  margin-left: 0;
  margin-right: auto;
}

.ck-view p + .image-style-align-left,
.ck-view p + .image-style-align-right,
.ck-view p + .image-style-side {
  margin-top: 0;
}

.ck-view .image-inline.image-style-align-left,
.ck-view .image-inline.image-style-align-right {
  margin-top: var(--ck-inline-image-style-spacing);
  margin-bottom: var(--ck-inline-image-style-spacing);
}

.ck-view .image-inline.image-style-align-left {
  margin-right: var(--ck-inline-image-style-spacing);
}

.ck-view .image-inline.image-style-align-right {
  margin-left: var(--ck-inline-image-style-spacing);
}

.ck-view code {
  background-color: #c7c7c74d;
  padding: 0.15em;
  border-radius: 2px;
}

.ck-view blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px #cccccc;
}

.ck-view[dir="rtl"] blockquote {
  border-left: 0;
  border-right: solid 5px #cccccc;
}

.ck-view .text-tiny {
  font-size: 0.7em;
}

.ck-view .text-small {
  font-size: 0.85em;
}

.ck-view .text-big {
  font-size: 1.4em;
}

.ck-view .text-huge {
  font-size: 1.8em;
}

.ck-view .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}

.ck-view hr {
  margin: 15px 0;
  height: 4px;
  background: #dedede;
  border: 0;
}

.ck-view pre {
  padding: 1em;
  color: #353535;
  background: #c7c7c74d;
  border: 1px solid #c4c4c4;
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}

.ck-view pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
