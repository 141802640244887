.sidebar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;

  &-menu {
    padding: 32px 16px;
    box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
    background-color: var(--color-white);
    overflow-y: scroll;

    .item {
      cursor: pointer;

      display: flex;
      gap: 8px;
      transition: 0.25s all;
      padding: 12px 10px;
      border-radius: 8px;
      align-items: center;
      text-decoration: none !important;
      margin-bottom: 8px;
      color: var(--color-black);

      &-icon {
        margin: 0 2px;
      }
      &-name {
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &.active {
        color: var(--color-primary);
        outline: none;
        position: relative;
        background-color: var(--color-primary-20);
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  &-content {
    flex: 1;
    overflow-y: scroll;
    padding: 16px;
  }
}
