@import "src/assets/styles/timeline.scss";

.color-hint {
  display: flex;
  align-items: center;
  gap: 8px;
  & .color {
    width: 8px;
    height: 8px;
    border-radius: 2px;
  }
  & .name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }
}
