.ant {
  &-app {
    line-height: 1.5;
  }
  &-table {
    border: 1px solid var(--color-gray-100);
    box-shadow: var(--shadow-md);
    border-radius: 10px !important;

    * {
      scrollbar-width: thin !important;
      scrollbar-color: var(--color-primary) var(--color-white) !important;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 8px !important;
    }

    *::-webkit-scrollbar-track {
      background: var(--color-white) !important;
    }

    *::-webkit-scrollbar-thumb {
      border-radius: 4px !important;
      background-color: var(--color-primary) !important;
      border: 1px solid var(--color-white) !important;
    }
    &-title {
      border-bottom: 1px solid var(--color-gray-100) !important;
      overflow-x: scroll !important;
    }
    &-content {
      overflow-x: scroll !important;
      overflow-y: hidden !important;
    }
    &-thead > tr > th,
    &-thead > tr > td {
      background-color: var(--color-white) !important;
    }
    &-row-selected > td {
      background-color: var(--color-gray-50) !important;
    }

    &-cell {
      &-row {
        &-hover {
          background-color: var(--color-gray-50) !important;
        }
      }
    }
    &-tbody > tr:last-child > td {
      border-bottom: none;
    }
    &-tbody > tr:last-child > td:first-child {
      border-bottom-left-radius: 10px !important;
    }
    &-tbody > tr:last-child > td:last-child {
      border-bottom-right-radius: 10px !important;
    }
  }
  &-picker {
    padding: 7.5px 12px;
    &:hover {
      border-color: var(--color-gray-100) !important;
    }
    &-disabled {
      background-color: var(--color-gray-100) !important;
      border-color: var(--color-gray-100) !important;
    }
    &-focused {
      box-shadow: var(--shadow-md) !important;
      border-color: var(--color-gray-100) !important;
    }
  }
  &-popover {
    z-index: 10000 !important;
  }
  &-tabs {
    &-nav {
      background-color: var(--color-white);
      padding: 0px;
      border-bottom: none !important;
      border-top: none !important;
    }
    &-content-holder {
      width: 100%;
    }
  }
  &-pagination {
    background-color: var(--color-white);
    padding: 4px;
    border-radius: 8px;
    height: 48px;
    display: flex;
    align-items: center;
    &-item {
      line-height: 1.5;
      &-active {
        background-color: var(--color-black) !important;
        color: var(--color-white) !important;
        a {
          color: var(--color-white) !important;
        }
      }
    }
  }
  &-select {
    &-selector {
      height: 48px !important;
      padding: 8px 12px !important;
    }
    &-item {
      padding: 5px 4px !important;
      text-align: center !important;
    }
  }
  &-picker-dropdown {
    z-index: 10000000 !important;
  }
  &-picker-dropdown &-picker-panel-container &-picker-presets ul li {
    border-radius: 6px !important;
    padding: 10px 12px !important;
    background-color: var(--color-gray-50) !important;
    &:hover {
      background-color: var(--color-gray-100) !important;
    }
  }
  &-select {
    height: 48px;
    &-clear {
      display: none !important;
    }
    &-selection-placeholder {
      color: var(--color-gray-400) !important;
    }
    &-selector {
      padding: 12px 16px !important;
      min-height: 48px !important;
      transition: 0.25s all !important;
      font-size: 14px !important;
      color: var(--color-black) !important;
      background-color: var(--color-white) !important;
      resize: none !important;
      border-radius: 7px !important;
      border: 1px solid var(--color-gray-100) !important;
      box-shadow: var(--shadow-md) !important;
      cursor: pointer !important;
      display: flex !important;
      align-items: center !important;
      &:hover,
      &:focus,
      &:active {
        outline: none !important;
        color: inherit !important;
        border-color: var(--color-gray-100) !important;
        box-shadow: var(--shadow-md) !important;
      }
    }
    &-tree {
      &-switcher,
      &-switcher_close {
        width: 24px !important;
        display: flex !important;
        justify-items: center !important;
        align-items: center !important;
      }
      &-node {
        border-radius: 4px;
        font-size: 14px;
        &-selected {
          background-color: var(--color-primary-8) !important;
          color: var(--color-primary) !important;
        }
      }
    }
  }
  &-image-preview {
    &-mask {
      z-index: 1001 !important;
    }
    &-wrap {
      z-index: 1002 !important;
    }
  }
}
