aside {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 360px;
  min-width: 32px;
  color: var(--color-white);
  .spinner-holder {
    width: 100%;
    height: 100%;
  }
  .company-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .collapse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    position: absolute;
    top: 69px;
    right: -12px;
    background-color: var(--color-white);
    transition: 0.25s;
    z-index: 10;
    &.active {
      transform: rotate(180deg);
    }
  }
  .menu-block {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: -12px;
      bottom: 0;
      right: -12px;
      background-color: var(--color-gray-800);
      height: 1px;
    }
    &--last::after {
      content: unset;
    }
  }
  .menu-add-button {
    &::after {
      border-radius: inherit;
      position: absolute;
      inset: 0;
      background-color: var(--color-black);
      z-index: -1;
    }
  }
  .menu {
    overflow-x: hidden;
    transition: 0.25s all;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //height: calc(100% - 52px);
    padding: 0 12px 24px 12px;

    &-item {
      cursor: pointer;

      display: flex;
      gap: 8px;
      transition: 0.25s all;
      padding: 10px 8px;
      border-radius: 8px;
      align-items: center;
      text-decoration: none !important;
      margin-bottom: 8px;
      color: var(--color-white);

      &-icon {
        margin: 0 2px;
      }
      &-name {
        flex: 1;
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-opener {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &.active-menu {
        transition: 0.25s all ease-in-out;
        color: var(--color-primary);
      }

      &.active {
        color: var(--color-primary);
        outline: none;
        position: relative;
        background-color: var(--color-primary-20);

        &::after {
          content: "";
          position: absolute;
          right: 12px;
          background: var(--color-primary);
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }
    &-profile {
      display: flex;
      gap: 8px;
      cursor: pointer;
      transition: 0.25s all;
      background-color: var(--color-gray-600);
      border: 1px solid #2c3844;
      padding: 10px 8px;
      border-radius: 8px;
      align-items: center;
      text-decoration: none !important;

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 12px;
        min-width: 24px;
        min-height: 24px;
        background-color: #3b4d5f;
      }
      &-name {
        font-size: 13px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--color-white);
      }
      &:hover {
        border: 1px solid #3d4e5f;
      }
    }
  }
}
