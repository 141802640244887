.wrapper {
  text-align: left;
  background-color: var(--color-black);
  width: 100%;
  display: grid;
  flex-direction: row;
  align-items: flex-start;
  transition:
    grid-template-columns 0.25s ease-in-out,
    padding 0.25s ease-in-out;
  &.big-menu {
    grid-template-columns: 192px calc(100% - 192px);
  }
  &.small-menu {
    grid-template-columns: 64px calc(100% - 64px);
    aside {
      .menu-item.active {
        width: auto;
        &::after {
          top: 4px;
          right: 4px;
        }
      }
    }
  }

  &-content {
    display: grid;
    grid-template-columns: 100%;
    min-width: 320px;

    &:has(.onboard-aside) {
      min-width: 320px + 331px;
      grid-template-columns: calc(100% - 331px) 331px;
    }

    .onboard-aside {
      display: unset;
      min-width: 331px;
      background-color: var(--color-white);
      border-left: 1px solid var(--color-gray-200);
    }
  }

  .right-side {
    width: 100%;
    transition: 0.25s a;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    background-color: var(--color-white);
    align-self: stretch;
    flex-grow: 1;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 320px;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    background-color: var(--color-white);
    transition: grid-template-columns 0.25s ease-in-out;
    &.big-menu {
      grid-template-columns: 192px;
    }
    &.small-menu {
      grid-template-columns: 1fr;
    }
  }
}
