.btn {
  @apply relative flex items-center outline-none outline-0 outline-transparent justify-center flex-row 
  content-center p-3 text-sm transition-all duration-200 text-black border-0 border-transparent rounded-lg select-none 
  align-middle text-center disabled:opacity-50 disabled:border-transparent cursor-pointer
  focus:outline-none focus:outline-0 focus:outline-transparent focus:shadow-none focus:border-0 focus:border-transparent focus:text-black focus:bg-transparent
  active:outline-none active:outline-0 active:outline-transparent active:shadow-none active:border-0 active:border-transparent active:text-black active:bg-transparent
  hover:outline-none hover:outline-0 hover:outline-transparent hover:shadow-none hover:border-0 hover:border-transparent hover:text-black hover:bg-transparent;

  &-group {
    display: inline-flex;
    position: relative;
    vertical-align: middle;

    > :not(.btn-check:first-child) + .btn,
    > .btn-group:not(:first-child) {
      margin-left: -1px;
    }

    > .btn:not(:last-child):not(.dropdown-toggle),
    > .btn.dropdown-toggle-split:first-child,
    > .btn-group:not(:last-child) > .btn {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .btn:nth-child(n + 3),
    > :not(.btn-check) + .btn,
    > .btn-group:not(:first-child) > .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.btn-card {
  @apply bg-white border border-gray-100 shadow-md text-black outline-none outline-0 outline-transparent cursor-pointer;
}
.btn-card:hover,
.btn-card:focus,
.btn-card:active {
  @apply bg-white border border-gray-100 shadow-md text-black outline-none outline-0 outline-transparent;
}

.btn > .remove {
  @apply absolute -top-1.5 -right-1.5 min-w-[16px] min-h-[16px] bg-white text-black text-[10px]
  rotate-45 p-1 rounded-full shadow-sm cursor-pointer;
}

.btn > .remove:hover {
  @apply bg-alert text-white;
}

.btn-icon {
  @apply bg-gray-70 text-black hover:bg-gray-100;
}

.btn-add {
  @apply font-sans p-3 leading-[14px] text-sm bg-black text-white focus:text-white active:text-white hover:text-white;
}

.btn-black {
  @apply bg-black text-white;
}
.btn-black:focus,
.btn-black:active,
.btn-black:hover {
  @apply text-white;
}

@each $color-name, $color-value in $colors {
  .btn-#{"" + $color-name} {
    background-color: #{rgba($color-value, 1)} !important;
    &:focus,
    &:active,
    &:hover {
      border: 0px solid transparent;
      background-color: #{rgba($color-value, 0.8)} !important;
    }
    .btn-outline {
      border: transparent;
      border: 1px solid #{rgba($color-value, 1)} !important;
      &:focus,
      &:active,
      &:hover {
        color: #{rgba($color-value, 0.8)} !important;
        border: 1px solid #{rgba($color-value, 0.8)} !important;
      }
    }
  }
  .btn-color-#{"" + $color-name} {
    color: #{rgba($color-value, 1)} !important;
    &:focus,
    &:active,
    &:hover {
      border: 0px solid transparent;
      color: #{rgba($color-value, 1)} !important;
    }
  }
  @each $opacity-name, $opacity-value in $opacity {
    .btn-#{"" + $color-name}-#{"" + $opacity-name} {
      background-color: #{rgba($color-value, $opacity-value)} !important;
    }
  }
}
