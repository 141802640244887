$primary: #a165fd;
$success: #0bd360;

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

@import "assets/styles/colors";
@import "assets/styles/_mixins";
@import "assets/styles/_variables";
@import "assets/styles/button";
@import "assets/styles/form";
@import "assets/styles/card";
@import "assets/styles/modal";
@import "assets/styles/file-upload";
@import "assets/styles/ckeditor-view";
@import "assets/styles/ant";

$font-family: -apple-system, BlinkMacSystemFont, "SF-Pro-Display", "Segoe UI", "Roboto", "Oxygen", "Cantarell",
  "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

.material-symbols-rounded {
  font-variation-settings:
    "FILL" 1,
    "wght" 400,
    "GRAD" 0,
    "opsz" 24;
  font-family: "Material Symbols Rounded" !important;
  font-size: 20px;
}

.font-receipe {
  font-family: "Inconsolata", monospace;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "wdth" 100;
  letter-spacing: -0.5px !important;
}

body {
  overflow: auto;
  height: 100%;
  margin: 0;
  width: 100vw !important;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: normal !important;
}

:root {
  --bs-link-color: var(--color-primary);
  --app-height: 100%;
  --toastify-toast-width: 372px;
  --bs-border-color: var(--color-gray-100);

  --ck-border-radius: 7px;
  --ck-inner-shadow: none;
  --ck-drop-shadow: var(--shadow-md);
  --ck-drop-shadow-active: var(--shadow-md);
  --ck-spacing-unit: 0.6em;
  --ck-spacing-large: calc(var(--ck-spacing-unit) * 1.5);
  --ck-spacing-standard: var(--ck-spacing-unit);
  --ck-spacing-medium: calc(var(--ck-spacing-unit) * 0.8);
  --ck-spacing-small: calc(var(--ck-spacing-unit) * 0.5);
  --ck-spacing-tiny: calc(var(--ck-spacing-unit) * 0.3);
  --ck-spacing-extra-tiny: calc(var(--ck-spacing-unit) * 0.16);
  --ck-color-base-foreground: var(--color-gray-70);
  --ck-color-base-background: var(--color-white);
  --ck-color-base-border: var(--color-gray-100);
  --ck-color-base-action: var(--color-primary);
  --ck-color-base-focus: var(--color-primary);
  --ck-color-base-text: var(--color-black);
  --ck-color-base-active: var(--color-primary);
  --ck-color-base-active-focus: var(--color-primary);
  --ck-color-base-error: var(--color-red);
  --ck-color-focus-border-coordinates: 264, 97%, 69%;
  --ck-color-focus-border: hsl(var(--ck-color-focus-border-coordinates));
  --ck-color-focus-outer-shadow: var(--color-primary-8);
  --ck-color-focus-disabled-shadow: var(--color-primary-20);
  --ck-color-focus-error-shadow: var(--color-red-20);
  --ck-color-text: var(--ck-color-base-text);
  --ck-color-shadow-drop: var(--color-black-8);
  --ck-color-shadow-drop-active: var(--color-black-20);
  --ck-color-shadow-inner: var(--color-black-8);
  --ck-color-button-default-background: transparent;
  --ck-color-button-default-hover-background: #f0f0f0;
  --ck-color-button-default-active-background: #f0f0f0;
  --ck-color-button-default-disabled-background: transparent;
  --ck-color-button-on-background: var(--color-primary-8);
  --ck-color-button-on-hover-background: #dbecff;
  --ck-color-button-on-active-background: #dbecff;
  --ck-color-button-on-disabled-background: #f0f2f4;
  --ck-color-button-on-color: var(--color-primary);
  --ck-color-button-action-background: var(--ck-color-base-action);
  --ck-color-button-action-hover-background: var(--color-green);
  --ck-color-button-action-active-background: var(--color-green);
  --ck-color-button-action-disabled-background: var(--color-green);
  --ck-color-button-action-text: var(--ck-color-base-background);
  --ck-color-button-save: var(--color-green);
  --ck-color-button-cancel: var(--color-red);
  --ck-color-switch-button-off-background: #939393;
  --ck-color-switch-button-off-hover-background: #7d7d7d;
  --ck-color-switch-button-on-background: var(--ck-color-button-action-background);
  --ck-color-switch-button-on-hover-background: var(--color-green);
  --ck-color-switch-button-inner-background: var(--ck-color-base-background);
  --ck-color-switch-button-inner-shadow: rgba(0, 0, 0, 0.1);
  --ck-color-dropdown-panel-background: var(--ck-color-base-background);
  --ck-color-dropdown-panel-border: var(--ck-color-base-border);
  --ck-color-input-background: var(--ck-color-base-background);
  --ck-color-input-border: var(--ck-color-base-border);
  --ck-color-input-error-border: var(--ck-color-base-error);
  --ck-color-input-text: var(--ck-color-base-text);
  --ck-color-input-disabled-background: #f2f2f2;
  --ck-color-input-disabled-border: var(--ck-color-base-border);
  --ck-color-input-disabled-text: #757575;
  --ck-color-list-background: var(--ck-color-base-background);
  --ck-color-list-button-hover-background: var(--ck-color-button-default-hover-background);
  --ck-color-list-button-on-background: var(--ck-color-button-on-color);
  --ck-color-list-button-on-background-focus: var(--ck-color-button-on-color);
  --ck-color-list-button-on-text: var(--ck-color-base-background);
  --ck-color-panel-background: var(--ck-color-base-background);
  --ck-color-panel-border: var(--ck-color-base-border);
  --ck-color-toolbar-background: var(--ck-color-base-background);
  --ck-color-toolbar-border: var(--ck-color-base-border);
  --ck-color-tooltip-background: var(--ck-color-base-text);
  --ck-color-tooltip-text: var(--ck-color-base-background);
  --ck-color-engine-placeholder-text: var(--color-primary-20);
  --ck-color-upload-bar-background: var(--color-primary);
  --ck-color-link-default: var(--color-primary);
  --ck-color-link-selected-background: rgba(31, 176, 255, 0.1);
  --ck-color-link-fake-selection: rgba(31, 176, 255, 0.3);
  --ck-color-highlight-background: var(--color-primary-20);
  --ck-color-widget-hover-border: rgba(162, 101, 253, 0.5);
  --ck-disabled-opacity: 0.5;
  --ck-focus-outer-shadow-geometry: 0 0 0 3px;
  --ck-focus-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-outer-shadow);
  --ck-focus-disabled-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-disabled-shadow);
  --ck-focus-error-outer-shadow: var(--ck-focus-outer-shadow-geometry) var(--ck-color-focus-error-shadow);
  --ck-focus-ring: 1px solid var(--ck-color-focus-border);
  --ck-line-height-base: 1.84615;
  --ck-font-face: $font-family;
  --ck-font-size-base: 12px;
  --ck-font-size-tiny: 8px;
  --ck-font-size-small: 11px;
  --ck-font-size-normal: 14px;
  --ck-font-size-big: 21px;
  --ck-font-size-large: 24px;
  --ck-icon-size: 21px;
  --ck-ui-component-min-height: 36px;

  touch-action: pan-x pan-y;
}

.Toastify__toast {
  position: relative;
  margin-bottom: 12px;
  padding: 16px;
  border-radius: 12px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  font-family: $font-family;
  cursor: default;

  &-body {
    margin: auto 0;
    flex: 1 1 auto;
    padding: 6px;
    display: flex;
    align-items: center;
    padding: 0;
  }
}

.accordion {
  --bs-accordion-border-radius: 8px;
  --bs-accordion-inner-border-radius: 8px;
  --bs-accordion-btn-padding-x: 16px;
  --bs-accordion-btn-padding-y: 12px;
}

.image-preview {
  position: relative;
  img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 8px;
  }
  .remove {
    cursor: pointer;
    position: absolute;
    top: -6px;
    right: -6px;
    min-width: 16px;
    min-height: 16px;
    background-color: var(--color-white);
    color: black;
    font-size: 13px;
    transform: rotate(45deg);
    padding: 4px;
    border-radius: 50%;
    box-shadow: var(--shadow-sm);
    &:hover {
      background-color: var(--color-red);
      color: var(--color-white);
    }
  }
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
}

.rotated {
  transition: 0.25s all;
  &-180 {
    transform: rotate(180deg);
  }
}

.container {
  border: 1px solid var(--color-gray-100);
  width: 360px;
  min-width: 360px;
  border-radius: 8px;
  background-color: var(--color-gray-100);
}

.link {
  color: black !important;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.col {
  flex: 1 0;
}

a {
  @apply hover:text-primary focus:text-primary active:text-primary;
}
