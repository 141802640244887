@import "bootstrap/scss/modal";

.modal {
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: 12px;
  --bs-modal-padding: 24px;
}

.modal-xl {
  --bs-modal-width: min(calc(100% - 32px), 1440px);
}

.modal-body {
  font-size: 14px;
  padding-top: 32px;
  padding-bottom: 32px;
}

.modal-content {
  box-shadow: 0px 10px 35px -10px rgba(0, 0, 0, 0.5);
}

.modal-remove {
  position: absolute;
  top: 6px;
  right: 6px;
  font-size: 12px;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-100);
  box-shadow: var(--shadow-md);
  color: var(--color-black);
  &:hover {
    background-color: var(--color-white);
    border: 1px solid var(--color-gray-100);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
    color: var(--color-black);
  }
}

.modal-grab-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 34px;
  margin: 0;
  &::after {
    content: "";
    top: 8px;
    left: 50%;
    width: 40px;
    height: 5px;
    background-color: #dadce0;
    border-radius: 100px;
    position: absolute;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.modal.fade .modal-dialog {
  transition: transform 0.25s ease-out;
  transform: translateY(48px);
}

.modal.show .modal-dialog {
  transform: none;
}

.subscription-modal {
  width: 960px;
  min-width: 960px;
  .modal-body {
    padding: 0;
  }
}

@media screen and (max-width: 768px) {
  .subscription-modal {
    width: 100vw;
    min-width: 100vw;
  }
}

@media screen and (max-width: 1024px) {
  .modal {
    --bs-modal-border-width: 0;
    --bs-modal-border-radius: 16px;
    --bs-modal-padding: 24px;
  }
}

@media screen and (max-width: 768px) {
  .modal-content {
    width: 100%;
  }
  .modal {
    --bs-modal-width: 100%;
    --bs-modal-margin: auto 0 0 0;
    --bs-modal-border-width: 0;
    --bs-modal-border-radius: 12px 12px 0px 0px;
    --bs-modal-padding: 24px;
  }
  .modal-body {
    max-height: 80vh;
    min-height: 160px;
    overflow-y: scroll;
    padding: 12px 16px;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .modal-fullscreen > .modal-content > .modal-body {
    max-height: unset;
  }
  .modal-dialog {
    width: 100%;
    position: fixed;
    bottom: 0;
  }
}
