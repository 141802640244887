$item-color: var(--color-white);
$item-background: var(--color-black);
$item-border: 1px solid var(--color-white-0);
$item-selected-color: white;
$item-selected-background: #ffc107;
$item-selected-border: 1px solid #ff9800;

$row-background-even: transparent;
$row-background-odd: transparent;
$row-height: 60px !important;

$border-color: var(--color-gray-100);
$border-width: 1px;
$thick-border-width: 2px;
$sidebar-color: var(--color-white);
$sidebar-background-color: var(--color-red);
$list-item-padding: 8px 12px;
$weekend: transparent;

.react-calendar-timeline {
  border: 1px solid var(--color-gray-100);
  background-color: var(--color-white);

  * {
    box-sizing: border-box;
  }

  .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    border-top: 1px solid var(--color-gray-100) !important;
  }

  .rct-sidebar {
    box-shadow: 10px 0px 12px var(--color-black-8) !important;
    background-color: var(--color-white) !important;
    border-right: 1px solid var(--color-gray-100) !important;
    z-index: 8;
  }

  .rct-scroll {
    display: inline-block;
    white-space: normal; // was set to nowrap in .rct-outer
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none;
  }

  .rct-item {
    border-radius: 2px;

    &:hover {
      z-index: 8;
    }

    .rct-item-content {
      position: sticky;
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      color: var(--color-black);
    }
  }

  .rct-sidebar {
    overflow: hidden;
    white-space: normal; // was set to nowrap in .rct-outer
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border-right: $border-width solid $border-color;

    &.rct-sidebar-right {
      border-right: 0;
      border-left: $border-width solid $border-color;
    }

    .rct-sidebar-row {
      padding: $list-item-padding;
      overflow-x: scroll;
      overflow-y: hidden;
      box-sizing: border-box;
      margin: 0;
      border-bottom: $border-width solid $border-color;
      line-height: inherit !important;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      &.rct-sidebar-row-odd {
        background: $row-background-odd;
        z-index: 8;
      }
      &.rct-sidebar-row-even {
        background: $row-background-even;
        z-index: 8;
      }
    }
  }

  .rct-vertical-lines {
    .rct-vl {
      position: absolute;
      border-left: 1px solid $border-color;
      z-index: 5;
      &.rct-vl-first {
        border-left-width: 2px;
      }
      &.rct-day-6,
      &.rct-day-0 {
        background: $weekend;
      }
    }
  }

  .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;

    .rct-hl-even,
    .rct-hl-odd {
      border-bottom: $border-width solid $border-color;
      box-sizing: border-box;
      z-index: 6;
    }
    .rct-hl-odd {
      background: $row-background-odd;
      height: $row-height;
    }
    .rct-hl-even {
      background: $row-background-even;
      height: $row-height;
    }
  }

  .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: $item-background;
    z-index: 8;
  }

  .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid transparent;
    color: var(--color-black);
    cursor: pointer;
    font-size: 10px;
    background-color: var(--color-gray-50);
    border-left: none;
  }

  .rct-dateHeader-primary {
    font-size: 16px;
    background-color: var(--color-gray-50);
    border-left: 2px solid var(--color-gray-100);
    border-right: 2px solid var(--color-gray-100);
    color: var(--color-black);
  }

  .rct-header-root {
    background: var(--color-gray-100);
    color: var(--color-black);
    border-bottom: none;
  }

  .rct-calendar-header {
    border: none;
  }
}
