.integration {
  &-banner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    align-items: center;
    border-radius: 12px;
    min-width: 800px;
    flex-grow: 0;
    height: 240px;
    width: 100%;
    background: linear-gradient(90deg, #7853e2 0%, #ae6def 100%);

    &-text {
      padding: 32px;
    }

    &-image {
      height: 240px;
      object-fit: contain;
    }

    @media (max-width: 1024px) {
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: auto;
      min-width: auto;

      &-text {
        padding: 16px;
      }

      &-image {
        width: 100%;
        max-width: 300px;
        height: auto;
      }
    }

    @media (max-width: 768px) {
      &-text {
        font-size: 14px;
      }

      &-image {
        max-width: 200px;
      }
    }
  }

  &-list {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    &-icon {
      width: 48px;
      height: 48px;
      padding: 8px;
      background-color: var(--color-gray-70);
      border-radius: 6px;
    }
    @media (max-width: 1024px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 16px;

      &-icon {
        width: 36px;
        height: 36px;
        padding: 6px;
      }
    }
  }
}
