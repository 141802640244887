.sale {
  &-tab {
    width: 100%;
    border: none;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    &-nav {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: end;
      &-link {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        color: var(--color-gray-500);
        font-weight: 400;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: 0.25s;
        &.active {
          color: var(--color-primary);
          border-bottom: 2px solid var(--color-primary);
        }
      }
      &-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        min-width: 24px;
        height: 24px;
        background-color: var(--color-primary-10);
        color: var(--color-primary);
      }
    }
  }
  &-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    border-radius: 10px;
    padding: 16px;
    border: 1px solid var(--color-gray-100);
    background-color: var(--color-white);
    box-shadow: var(--shadow-md);
    &-image {
      min-width: 56px;
      max-width: 56px;
      min-height: 56px;
      max-height: 56px;
      border-radius: 8px;
      background-color: var(--color-gray-70);
    }
  }
  &-summary {
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: var(--color-gray-70);
    border-radius: 8px;
    padding: 20px;
    &-divider {
      height: 0px;
      border-bottom: 1px dashed var(--color-gray-200);
      flex: 1;
    }
  }
}
