.auth {
  font-family: "SF-Pro-Display", sans-serif;
  width: 100%;
  max-width: 560px;
  min-width: 320px;
  padding: 16px 12px;

  @media screen and (min-width: 0) and (max-width: 425px) {
    max-width: 100%;
    width: 100%;
  }
  &-icon {
    align-self: center;
    width: 280px;
  }
  .label {
    font-family: "SF-Pro-Display", sans-serif;
    margin-top: 4px;
    text-align: center;
    color: var(--color-gray-400);
    font-size: 18px;
  }
  &__submit-btn {
    font-size: 15px;
    font-family: "SF-Pro-Display", sans-serif;
  }
}
